<template>
    <div class="packages">
        <div class="packages-container" v-if="!selectedPlan">
            <h1>{{ $t('package_title') }}</h1>
            <h4>{{ $t('package_desc') }}</h4>

            <div class="plans">
                <div class="plan" v-for="plan in plans" :key="plan.id">
                    <p class="title">{{ plan[`${language}_title`] }}</p>
                    <p class="price">{{ plan.price / plan.id }} <span>{{ plan.currency }}</span></p>
                    <!-- <p class="desc">Eligible for four ({{ plan.takes }}) takes only and license valid for {{ plan.id }} year{{plan.id > 1 ? 's' : ''}}</p> -->
                    <p class="desc">{{ plan[`${language}_description`] }}</p>
                    <button @click="selectedPlan = plan">{{ $t('choose_plan') }}</button>
                </div>
            </div>
        </div>

        <div class="checkout-container" v-else>
            <h2>{{ $t('checkout') }}</h2>
            <div class="plan-wrapper">
                <p class="title">{{ $t('personal_plan') }}</p>
                <div class="details">
                    <p class="price">{{ selectedPlan.price }} {{ selectedPlan.currency }}</p>
                    <p class="year">{{ selectedPlan.id }} Year</p>
                    <a class="change-plan" @click="selectedPlan = null">{{ $t('change_plan') }}</a>
                </div>
            </div>

            <!-- <p class="desc">{{ selectedPlan.price }} HKD billed for {{ selectedPlan.year }} Year{{selectedPlan.id > 1 ? 's' : ''}} (only 1.45HKD/month)</p> -->
            <div class="term-wrapper">
                <router-link to="/terms" class="terms" target="_blank">Terms and condition apply</router-link>
            </div>
            <div ref="paypal" class="paypal-btn"></div>
        </div>

        <div class="modal-container" v-if="showModal">
            <div class="modal" v-if="isSuccess">
                <p class="title">Success!</p>
                <p class="desc subscribed">You Are now subscribed to Findlinc. Valid until {{ end | formatDateWithTime }}</p>
                <button @click="close('welcome')" class="close">Close</button>
            </div>

            <div class="modal" v-if="isFailed">
                <p class="title">Payment Unsuccessful</p>
                <p class="desc sorry">Sorry, we weren't able to complete your payment at this time.</p>
                <p class="desc try"> Please try again later.</p>
                <button @click="close('dashboard')" class="close">Close</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
    data () {
        return {
			end: '',
            showModal: false,

            paypalButton: null,
            selectedPlan: null,

            //for paypal
            isSuccess: false,
			isFailed: false,
			orderId: null,
            paypalBtnStyle: {
				label: "checkout",
				size: "medium",
				shape: "rect",
				color: "blue"
			},

            // plans: [
            //     {
            //         "id": 1,
            //         "name": "1 Year Plan",
            //         "price": "42",
            //         "currency": "hkd",
            //         "duration": 60,
            //         "takes": 4
            //     },
            //     {
            //         "id": 2,
            //         "name": "2 Year Plan",
            //         "price": "70",
            //         "currency": "hkd",
            //         "duration": 120,
            //         "takes": 4
            //     },
            //     {
            //         "id": 3,
            //         "name": "3 Year Plan",
            //         "price": "90",
            //         "currency": "hkd",
            //         "duration": 180,
            //         "takes": 4
            //     }
            // ]
        }
    },

    computed: {
        ...mapFields(['plans', 'language']),
    },

    async created () {
        await this.$store.dispatch('getPlans')
	},

    watch: {
        selectedPlan (val) {
            if (val) {
                const clientID = process.env.NODE_ENV === 'production' && process.env.VUE_APP_SERVER === 'prod' ?
				'AXjhnCsPm_jQIs5E8RwjIAieA4eJF28MfQD7VfypwvqVV9Zo2PgTXEFqnyEHdxXD3951at9nbcB2BtHU' :
				'AVsNKdMYkakS9igM_XFwSYrMeCG-3aOuRs_pQZ7rLG7i9mxbI952wfz1OERilTeLfctCgKUx6VbYyR6G'

                const script = document.createElement('script')
                script.src = `https://www.paypal.com/sdk/js?client-id=${clientID}&disable-funding=credit,card&currency=TWD`
                script.addEventListener('load', this.setLoaded)
                document.head.appendChild(script)
            } else {
                this.paypalButton.close()
            }
        }
    },

    methods: {
        setLoaded () {
			this.paypalButton = window.paypal.Buttons({
				style: {
					label: "paypal",
					shape: "rect",
					color: "gold"
                },

				createOrder: (data, actions) => {
					return actions.order.create({
						purchase_units: [
							{
								amount: {
									currency_code: 'TWD',
									value: this.selectedPlan.price
								}
							}
						]
					})
				},

				onApprove: async (data, actions) => {
					const order = await actions.order.capture();
					// this.$store.commit('SET_LOADING', true)
					this.onPaymentSuccess(order)
				},

				onError: err => {
					console.log(err);
					this.isFailed = true
                    this.showModal = true
				}

			})
            
            this.paypalButton.render(this.$refs.paypal)
		},

        async onPaymentSuccess(data) {
            //modal then go to welcome page
			this.orderId = data.purchase_units[0].payments.captures[0].id

            
			const result = await this.$store.dispatch('subscribe', {
                transaction_id: this.orderId,
				plan_id: this.selectedPlan.id
			})

			if (result.status) {
                this.end = result.end_date
                this.isSuccess = true
                this.showModal = true
			} else {
                this.isFailed = true
                this.showModal = true
            }
		},

        close (val) {
            this.$router.push(val)
            this.showModal = false
        }
    }
}
</script>

<style lang="scss" scoped>
.packages {
    padding-top: 95px;
    padding-bottom: 50px;

    background-image: url('~@/assets/img/home_upperbg.png');
    background-repeat: no-repeat;
    background-size: 100%;

    display: flex;
    flex-direction: column;
    color: #221E1F;
    align-items: center;

    @include desktop {
        padding-top: 160px;
    }

    .packages-container {
        padding: 20px;

        h1 {
            font-family: 'Crimson Pro', sans-serif;
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 35px;
            text-align: center;
        }
    
        h4 {
            margin: 0 auto 50px;
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 0.32px;
            line-height: 20px;
            text-align: center;

            @include desktop {
                max-width: 500px;
            }
        }
    
        .plans {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;

            @include desktop {

            }
    
            .plan {
                border-radius: 3px;
                box-shadow: 0px 6px 6px #00000029;
                padding: 20px 25px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                margin-right: 20px;
                margin-bottom: 20px;

                &:last-child {
                    @include desktop {
                        margin-right: 0px;
                    }
                }
    
                .title {
                    font-family: 'Crimson Pro', sans-serif;
                    font-weight: 700;
                    font-size: 26px;
                    margin-bottom: 15px;
                }
    
                .price {
                    font-family: 'Crimson Pro', sans-serif;
                    font-weight: 600;
                    font-size: 30px;
                    margin-bottom: 20px;
                    color: #00AEEF;
    
                    span {
                        font-family: 'Crimson Pro', sans-serif;
                        font-size: 18px;
                        text-transform: uppercase;
                    }
                }
    
                .desc {
                    font-size: 14px;
                    color: #B8B8B8;
                    margin-bottom: 30px;
                    width: 200px;
                }
    
                button {
                    width: 126px;
                    border-radius: 4px;
                    border: 1px solid #00AEEF;
                    color: #00AEEF;
                    background-color: #ffffff;
                }
            }
    
    
        }
    }

    .checkout-container {
        color: #1E1E1E;
        padding: 20px;

        h2 {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 20px;
        }
        
        a {
            cursor: pointer;
        }

        .plan-wrapper {
            width: 320px;
            background: #F9F9F9;
            margin-bottom: 15px;
            padding: 30px 20px 20px;

            @include desktop {
                width: 450px;
            }

            .title {
                font-weight: 600;
                font-size: 18px;
                margin-bottom: 15px;
            }

            .details {
                display: flex;
                align-items: center;

                .price {
                    font-weight: 600;
                    font-size: 22px;
                    margin-right: 15px;
                    text-transform: uppercase;
                }

                .year {
                    font-size: 12px;
                }

                .change-plan {
                    margin-left: auto;
                    color:  #00AEEF;
                    font-weight: 600;
                    font-size: 14px;
                }
            }
        }

        .desc {
            color: #00AEEF;
            font-size: 18px;
            margin-bottom: 35px;
        }

        .term-wrapper {
            margin-bottom: 20px;

            .terms {
                color: #00AEEF;
                font-weight: 600;
                font-size: 14px;
            }
        }
    }

    .modal-container {
        position: fixed;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #000000a1;
        z-index: 999999;
        overflow-y: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        .modal {
            width: 400px;
            z-index: 999;
            padding : 90px 50px 20px;
            box-shadow: 0px 3px 6px #00000047;
            display: flex;
            flex-direction: column;
            text-align: center;
    
            background-image: url('~@/assets/img/home_upperbg.png');
            background-repeat: no-repeat;
            background-color: #ffffff;
            background-size: 100%;
    
            .title {
                color: #00AEEF;
                font-weight: 600;
                font-size: 20px;
                margin-bottom: 30px;
            }
    
            .desc {
                width: 260px;
                margin: auto;
                font-size: 14px;

                &.subscribed {
                    margin-bottom: 75px;
                }

                &.sorry {
                    margin-bottom: 20px;
                }

                &.try {
                    margin-bottom: 40px;
                }
            }
    
            .close {
                color: #FF6868;
                border: 1px solid #FF6868;
                background: #ffffff;
                font-size: 14px;
                width: 120px;
                margin: auto;
            }
        }
    }

}
</style>